define(['app', '$window', 'accessibilityFocusHelper'], (app, $window, accessibilityFocusHelper) => {
  const topProductCategory = () => {
    const component = {};
    const step = 150;
    const buttonMarginFallback = 10;
    let ticking = false;

    component.config = {
      selectors: {
        buttonSet: '.topProductCategorySet',
        header: '.responsiveProductListHeader',
        sortAndPagination: '.responsiveProductListPage_sortAndPagination ',
        button: '.topProductCategory',
        leftArrow: '.topProductCategoryWrapper_leftArrow',
        rightArrow: '.topProductCategoryWrapper_rightArrow',
        showMore: '.topProductCategorySet_showMore',
        showLess: '.topProductCategorySet_showLess',
        showMoreAttr: 'data-show-more',
        sideFacets: '.responsiveProductListPage_facets'
      },
      classes: {
        hide: 'topProductCategory-hide'
      }
    };

    component.init = (element) => {
      component.wrapper = element;
      component.buttonSet = component.wrapper.querySelector(component.config.selectors.buttonSet);
      component.sortAndPagination = document.querySelector(component.config.selectors.sortAndPagination);
      component.header = document.querySelector(component.config.selectors.header);
      component.leftArrow = component.wrapper.querySelector(component.config.selectors.leftArrow);
      component.rightArrow = component.wrapper.querySelector(component.config.selectors.rightArrow);
      component.buttons = component.buttonSet.querySelectorAll(component.config.selectors.button);
      component.showMore = component.wrapper.querySelector(component.config.selectors.showMore);
      component.showLess = component.wrapper.querySelector(component.config.selectors.showLess);
      component.sideFacets = document.querySelector(component.config.selectors.sideFacets);


      component.hiddenButtons = [];

      component.bind();
      component.moveComponentAboveSortAndPagination();
      component.displayArrows();

      if (component.showMore && component.showLess) {
        component.addShowMoreButton();
      }

      app.subscribe('topProductCategory/update', component.update);
    };

    component.bind = () => {
      component.leftArrow && component.leftArrow.addEventListener('click', () => component.navigateLeft(step));
      component.rightArrow && component.rightArrow.addEventListener('click', () => component.navigateRight(step));
      component.buttonSet.addEventListener('scroll', component.handleScroll, { passive: true });
      component.showMore && component.showMore.addEventListener('click', () => component.showButtonsClicked(true));
      component.showLess && component.showLess.addEventListener('click', () => component.showButtonsClicked(false));
      $window.addEventListener('resize', component.handleResize);
      $window.addEventListener('scroll', component.throttleResize);
    };

    component.update = () => {
      component.sortAndPagination = document.querySelector(component.config.selectors.sortAndPagination);
      component.header = document.querySelector(component.config.selectors.header);
      component.moveComponentAboveSortAndPagination();
    };

    component.handleScroll = () => {
      const scrollPosition = component.buttonSet.scrollLeft + component.wrapper.getBoundingClientRect().width;

      component.displayHTMLElement(component.rightArrow, scrollPosition < component.buttonSet.scrollWidth);
      component.displayHTMLElement(component.leftArrow, component.buttonSet.scrollLeft !== 0);
    };

    component.moveComponentAboveSortAndPagination = () => {
      if (component.header && component.sortAndPagination && !component.sideFacets) {
        component.wrapper.remove();
        component.sortAndPagination.insertAdjacentElement('beforebegin', component.wrapper);
      }

      if (!component.header && component.sortAndPagination) {
        component.wrapper.remove();
        component.header.insertAdjacentElement('beforebegin', component.wrapper);
      }

      if (component.header && component.sortAndPagination && component.sideFacets) {
        component.wrapper.remove();
        component.header.insertAdjacentElement('beforeend', component.wrapper);
      }
    };

    component.displayArrows = () => {
      if ($window.innerWidth >= 900) {
        component.displayHTMLElement(component.rightArrow, false);
        component.displayHTMLElement(component.leftArrow, false);
      } else {
        component.displayHTMLElement(
            component.rightArrow,
            component.wrapper.getBoundingClientRect().width < component.buttonSet.scrollWidth
        );
      }
    };

    component.handleResize = (e) => {
      component.displayArrows();
      if ($window.innerWidth < 900) {
        if (component.hiddenButtons.length > 0) {
          component.hiddenButtons.forEach(button => component.displayHTMLElement(button.element, true));
          component.hiddenButtons = [];
        }

        component.showMore && component.displayHTMLElement(component.showMore, false);
        component.showLess && component.displayHTMLElement(component.showLess, false);
      }

      if (e && $window.innerWidth >= 900 && component.showMore && component.showLess) {
        component.reArrangeButtons();
      }
    };

    component.reArrangeButtons = () => {
      const showMoreStyles = window.getComputedStyle(component.showMore);
      const showLessStyles = window.getComputedStyle(component.showLess);
      const direction = document.dir
          ? document.dir : document.getElementsByTagName("html")[0].getAttribute("dir");
      const buttonMargin = component.buttons && component.buttons.length > 0
          ? parseInt(window.getComputedStyle(component.buttons[0]).getPropertyValue(direction === 'rtl' ? 'margin-left' : 'margin-right'))
          : buttonMarginFallback;

      if (showMoreStyles && showMoreStyles.getPropertyValue('display') === 'block') {
        let diff = component.wrapper.getBoundingClientRect().width - component.buttonSet.getBoundingClientRect().width;
        let buttonToShow = component.hiddenButtons.length > 0 ? component.hiddenButtons[0] : null;
        let buttonWidth = buttonToShow ? (buttonToShow.width + buttonMargin) : null;

        while (buttonWidth !== null && buttonWidth <= diff) {
          component.displayHTMLElement(buttonToShow.element, true);
          component.hiddenButtons.shift();

          diff -= (buttonWidth + buttonMargin);
          buttonToShow = component.hiddenButtons.length > 0 ? component.hiddenButtons[0] : null;
          buttonWidth = buttonToShow ? buttonToShow.width : null;
        }

        if (component.hiddenButtons.length === 0) {
          component.displayHTMLElement(component.showMore, false);
        }

        //if show more is wrapped, hide closest button and move show more up
        component.positionShowMore();
      }

      if (showMoreStyles && showMoreStyles.getPropertyValue('display') === 'none' && showLessStyles) {
        const showLessDisplay = showLessStyles.getPropertyValue('display');

        if (showLessDisplay === 'none') {
          component.addShowMoreButton();
        } else if (component.getWrappedButtons().length === 0) {
          component.displayHTMLElement(component.showLess, false);
        }
      }
    };

    component.positionShowMore = () => {
      const firstButtonTop = component.buttons[0].getBoundingClientRect().top;
      const showMoreButtonTop = component.showMore.getBoundingClientRect().top;

      if (showMoreButtonTop > firstButtonTop) {
        let previousElement = component.showMore.previousElementSibling;

        while (previousElement) {
          if (window.getComputedStyle(previousElement).getPropertyValue('display') === 'block') {
            break;
          }
          previousElement = previousElement.previousElementSibling;
        }

        if (component.hiddenButtons && previousElement) {
          component.hiddenButtons.unshift({
            width: previousElement.getBoundingClientRect().width,
            element: previousElement
          });
          component.displayHTMLElement(previousElement, false);
        }
      }
    };

    component.throttleResize = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          component.handleResize();
          ticking = false;
        });

        ticking = true;
      }
    };

    component.addShowMoreButton = () => {
      let wrappedButtons = component.getWrappedButtons();

      if (wrappedButtons && wrappedButtons.length > 0) {
        let index = wrappedButtons[0] - 1;

        for (index; index < component.buttons.length; index++) {
          component.hiddenButtons.push({
            width: component.buttons[index].getBoundingClientRect().width,
            element: component.buttons[index]
          });
          component.displayHTMLElement(component.buttons[index], false);
        }

        component.displayHTMLElement(component.showMore, true);
      } else {
        component.displayHTMLElement(component.showMore, false);
        component.displayHTMLElement(component.showLess, false);
      }
    };

    component.getWrappedButtons = () => {
      let firstBtnRect = component.buttons && component.buttons.length > 0
          && component.buttons[0].getBoundingClientRect();
      let currBtnRect = {};
      let wrappedButtons = [];

      if (component.buttons && component.buttons.length > 1 && firstBtnRect) {
        for (let index = 1; index < component.buttons.length; index++) {
          currBtnRect = component.buttons[index].getBoundingClientRect();
          if (firstBtnRect.top < currBtnRect.top) {
            wrappedButtons.push(index);
          }
        }
      }

      return wrappedButtons;
    };

    component.showButtonsClicked = (showMore) => {
      component.buttonSet.classList.remove('fixedHeight');
      component.displayHTMLElement(showMore ? component.showMore : component.showLess, false);

      if (component.hiddenButtons && component.hiddenButtons.length > 0) {
        component.hiddenButtons.forEach(button => component.displayHTMLElement(button.element, showMore));
      }

      component.displayHTMLElement(showMore ? component.showLess : component.showMore, true);

      if (showMore) {
        accessibilityFocusHelper.focus(component.showLess);
      } else {
        accessibilityFocusHelper.focus(component.showMore);
      }
    };

    component.navigateRight = (scrollStep) => {
      let leftScroll = component.buttonSet.scrollLeft;
      let horizontalScroll = leftScroll + scrollStep;
      let scrollContainerWidth = component.buttonSet.scrollWidth;
      let scrollToMaxWidth = (horizontalScroll + component.wrapper.getBoundingClientRect().width) >= scrollContainerWidth;
      let scrollAmount = scrollToMaxWidth ? scrollContainerWidth : horizontalScroll;

      component.buttonSet.scrollTo(scrollAmount, 0);
      component.displayHTMLElement(component.rightArrow, !scrollToMaxWidth);
      component.displayHTMLElement(component.leftArrow, true);
    };

    component.navigateLeft = (scrollStep) => {
      const leftScroll = component.buttonSet.scrollLeft;
      const horizontalScroll = leftScroll - scrollStep;

      component.buttonSet.scrollTo(horizontalScroll > 0 ? horizontalScroll : 0, 0);
      component.displayHTMLElement(component.rightArrow, true);
      component.displayHTMLElement(component.leftArrow, horizontalScroll > 0);
    };

    component.displayHTMLElement = (element, show) => {
      if (show) {
        element.classList.remove(component.config.classes.hide);
      } else {
        element.classList.add(component.config.classes.hide);
      }
    };

    return component;
  };

  return topProductCategory;
});
